.container {
    padding-top: 40px;
	width: 80vw;
	margin: auto;
}

.paper {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px;
    margin: 20px;
    gap: 20px;
}